/**
 * @file 榜单插坑埋点
 */

import { daEventCenter } from 'public/src/services/eventCenter/index'

const daEventExpose = daEventCenter.getExposeInstance()

export const RANK_LIST_EXPOSE_CODE = 'rank_list_component_exposeCode'
export const RANK_ITEM_EXPOSE_CODE = '2-3-2'

// 店铺场景使用
class StoreItemRankAnalysis {
  flowRankingList = {}
  flowIndex = 0

  constructor({ flowRankingList, flowIndex }) {
    this.flowRankingList = flowRankingList || {}
    this.flowIndex = flowIndex || 0
  }

  get isListRank() {
    return [15, 75].includes(Number(this.carrierData.carrierSubType))
  }
  get contentCarrierId() {
    return this.flowRankingList.contentCarrierId || ''
  }
  get carrierData() {
    return this.flowRankingList.carrierData || {}
  }
  /**
   * @example {
   *  rankingFrom: 'recommend',
   *  srcModule: 'store_item',
   *  storeCode: 'store_code',
   *  showNewStyleInStoreItem: true,
   *  activityFrom: 'store_item',
   *  from: 'storeItem',
   * }
   */
  get customData() {
    return this.flowRankingList.customData || {}
  }

  /**
   * @public
   */
  get rankSaData() {
    const rankingType = this.flowRankingList.rankingType || this.flowRankingList.rankTypeText || ''
    const extendData = this.isListRank ? `\`cate_id=${this.carrierData.cateIds || '-'}` : ''
    const src_identifier = `ri=${this.carrierData.carrierSubType}\`rn=${rankingType}\`ps=${this.flowIndex + 1}\`jc=${this.contentCarrierId}` + extendData

    const extendAnalysisData = this.isListRank ? {
      content_id: this.contentCarrierId || '-',
      carriersubtype: this.carrierData.carrierSubType || '-',
      carriersubtypename: this.flowRankingList.carrierSubTypeName || '-',
      board_generate_type: this.flowRankingList.board_generate_type ||
        this.carrierData.carrier_combine_rule_list?.join('`') ||
        '-',
    } : {}

    return {
      ranking_from: (
        this.customData.rankingFrom ||
        this.flowRankingList.rankFrom ||
        (this.flowRankingList.recommendType === 'manual'
          ? 'customize'
          : 'recommend')
      ),
      src_module: 'store_item',
      src_identifier,
      activity_from: 'store_item',
      ...extendAnalysisData,
    }
  }

  get exposeHeaderConfig() {
    return {
      id: '1-5-1-40',
      prefix: 'RANK_COMP_SA_PREFIX',
      data: this.rankSaData,
    }
  }

  get clickHeaderConfig() {
    return {
      id: '1-5-1-43',
      data: this.rankSaData,
    }
  }

  /** @public */
  triggerClickGoods() {
    daEventCenter.triggerNotice({
      daId: '1-5-1-43',
      bindData: {
        ...this.rankSaData,
      },
    })
  }

  /** @public */
  resetRankExpose() {
    const daEventExpose = daEventCenter.getExposeInstance()
    daEventExpose?.resetAll?.('RANK_COMP_SA_PREFIX')
    daEventExpose?.resetAll?.(RANK_LIST_EXPOSE_CODE)
  }

  /**
   * @public
   */
  initRankExpose() {
    daEventExpose.subscribe({
      keycode: `${RANK_LIST_EXPOSE_CODE}\`${RANK_ITEM_EXPOSE_CODE}`,
      type: 'list',
    })
  }
}

// 泛列表场景使用
class ListRankAnalysis {

  flowRankingList = {}
  flowIndex = 0

  constructor({ flowRankingList, flowIndex }) {
    this.flowRankingList = flowRankingList || {}
    this.flowIndex = flowIndex || 0
  }

  formatInfoFlow(goods_id = '') {
    const flowRankingList = this.flowRankingList || {}
    const position = Number(this.flowIndex) + 1

    const goods = flowRankingList.goods || []
    let goodsFormat = goods.slice(0, 3).map((item, idx) => `${idx + 1}_${item.goods_id}`).join('|') || '-'
    if (goods_id) {
      const targetIdx = goods.findIndex(item => item.goods_id === goods_id)
      goodsFormat = targetIdx !== -1 ? `${targetIdx + 1}_${goods[targetIdx].goods_id}` : goodsFormat
    }
    const info_flow = [
      position,
      flowRankingList.contentCarrierId,
      '-',
      goodsFormat,
      flowRankingList.product_select_url_id || '-',
      flowRankingList.rec_mark || '-',
    ]
    return info_flow.join('`')
  }

  getCarrierLabel(goods_id = '') {
    const flowRankingList = this.flowRankingList || {}

    const goods = flowRankingList.goods || []
    let carrierLabel = '-'
    if(goods.slice(0, 3).some(item => item?.local_goods_id)) {
      carrierLabel = '-`-`-`-`-`-`-`-`-`' + goods.slice(0, 3).map((item) =>  `ypdg_goods_id_${item?.local_goods_id || '-'}`).join('|')
    }
    if (goods_id) {
      const targetIdx = goods.findIndex(item => item.goods_id === goods_id)
      if (goods[targetIdx]?.local_goods_id ) {
        carrierLabel = '-`-`-`-`-`-`-`-`-`' + `ypdg_goods_id_${goods[targetIdx].local_goods_id}`
      }
    }
    return carrierLabel
  }

  get src_module() {
    return 'ranking_list'
  }

  get rankSaData() {
    const position = Number(this.flowIndex) + 1
    const {
      carrierType,
      carrierTypeName,
      carrierSubType,
      carrierSubTypeName,
      contentCarrierId,
      cate_id,
      board_generate_type,
    } = this.flowRankingList || {}

    const src_identifier = [
      `tn=${window.SaPageInfo.page_name}`,
      `ci=${contentCarrierId}`,
      `ct=${carrierTypeName || ''}`,
      `cs=${carrierSubTypeName || ''}`,
      'si=-',
      `ps=${position}`,
      `jc=_${contentCarrierId}`,
      `cate_id=${cate_id || '-'}`,
    ].join('`')

    return {
      info_flow: this.formatInfoFlow(),
      carrier_label: this.getCarrierLabel(),
      src_module: this.src_module,
      content_id: contentCarrierId,
      src_identifier,
      carriersubtype: carrierSubType,
      carriersubtypename: carrierSubTypeName || '-',
      carriertype: carrierType || '-',
      carriertypename: carrierTypeName || '-',
      board_generate_type: board_generate_type || '-',
    }
  }

  get exposeHeaderConfig() {
    return {
      id: '1-5-1-44',
      prefix: 'RANK_COMP_SA_PREFIX',
      data: {
        ...this.rankSaData,
      },
    }
  }

  get clickHeaderConfig() {
    return {
      id: '1-5-1-45',
      data: {
        ...this.rankSaData,
        click_type: 'module',
      },
    }
  }

  /** @public */
  resetRankExpose() {
    const daEventExpose = daEventCenter.getExposeInstance()
    daEventExpose?.resetAll?.('RANK_COMP_SA_PREFIX')
  }

  /** @public */
  triggerClickGoods({ item }) {
    daEventCenter.triggerNotice({
      daId: '1-5-1-45',
      bindData: {
        ...this.clickHeaderConfig.data,
        info_flow: this.formatInfoFlow(item.goods_id),
        carrier_label: this.getCarrierLabel(item.goods_id),
        click_type: 'goods',
      },
    })
  }
}

/** 商详you may also like */
class DetailYmalRankAnalysis extends ListRankAnalysis {
  emitExposeRank = function () {}

  constructor({ flowRankingList, flowIndex, emitExposeRank }) {
    super({ flowRankingList, flowIndex })
    this.emitExposeRank = emitExposeRank
  }

  get src_module() {
    return 'ymal_rank'
  }

  get exposeHeaderConfig() {
    return {
      id: '1-5-1-44',
      prefix: 'RANK_COMP_SA_PREFIX',
      data: {
        ...this.rankSaData,
      },
      callback: this.emitExposeRank,
    }
  }
}

/** 购物车底部推荐 */
class CartRecommendAnalysis extends ListRankAnalysis {
  emitExposeRank = function () {}

  constructor({ flowRankingList, flowIndex }) {
    super({ flowRankingList, flowIndex })
  }

  get rankSaData() {
    const position = Number(this.flowIndex) + 1
    const {
      carrierType,
      carrierTypeName,
      carrierSubType,
      carrierSubTypeName,
      contentCarrierId,
      cate_id,
      scene_id,
      board_generate_type,
    } = this.flowRankingList || {}

    const src_identifier = [
      `tn=${window.SaPageInfo.page_name}`,
      `ci=${contentCarrierId}`,
      `ct=${carrierTypeName || ''}`,
      `cs=${carrierSubTypeName || ''}`,
      `si=${scene_id || '-'}`,
      `ps=${position}`,
      `jc=${carrierSubTypeName}_${contentCarrierId}`,
      `cate_id=${cate_id || '-'}`,
    ].join('`')

    return {
      info_flow: this.formatInfoFlow(),
      carrier_label: this.getCarrierLabel(),
      src_module: this.src_module,
      content_id: contentCarrierId,
      src_identifier,
      carriersubtype: carrierSubType,
      carriersubtypename: carrierSubTypeName || '-',
      carriertype: carrierType || '-',
      carriertypename: carrierTypeName || '-',
      board_generate_type: board_generate_type || '-',
      is_carousel: '-',
      is_operation_activity: '-',
    }
  }

  get src_module() {
    return 'infoflow'
  }
}

export const initFlowRankAnalysis = ({ flowRankingList, flowIndex, emitExposeRank }) => {
  daEventCenter.addSubscriber({ modulecode: '1-5-1' })
  const { from } = flowRankingList?.customData || {}
  if (from === 'detail_ymal') {
    return new DetailYmalRankAnalysis({ flowRankingList, flowIndex, emitExposeRank })
  }
  if (from === 'storeItem') {
    return new StoreItemRankAnalysis({ flowRankingList, flowIndex })
  }
  if (from === 'cart_recommend') {
    return new CartRecommendAnalysis({ flowRankingList, flowIndex })
  }
  return new ListRankAnalysis({ flowRankingList, flowIndex })
}
